import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import type { UserStateData } from 'src/types/users';
import { AXIOS_DELETE, AXIOS_GET, AXIOS_PATCH, AXIOS_POST, BASE_URL } from './service/apiService';
import {toastr} from 'react-redux-toastr';
import { me } from './auth';
import { snackbarGlobConfig } from 'src/globalConfig';

const initialState: UserStateData = {
    userList: [],
    roleList: [],
    projectList: [],
    page: 1,
    limit: 5,
    totalCount: 0,
    selectedUserObject: null,
    loader: false,
    deleteRecId: null,
    profileUpdateLoader: false
};

const slice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        getUsersList(state: UserStateData, action: PayloadAction<{ userList: any[], totalCount: number }>) {
            state.userList = action.payload.userList;
            state.totalCount = action.payload.totalCount;
        },
        getUserSingleData(state: UserStateData, action: PayloadAction<{ selectedUserObject: any }>) {
            state.selectedUserObject = action.payload.selectedUserObject
        },
        loader(state: UserStateData, action: PayloadAction<any>) {
            state.loader = action.payload.loader
        },
        getRoleList(state: UserStateData, action: PayloadAction<{ roleList: any[] }>) {
            state.roleList = action.payload.roleList
        },
        getProjectList(state: UserStateData, action: PayloadAction<{ projectList: any[] }>) {
            state.projectList = action.payload.projectList
        },
        setDeleteRecordId(state: UserStateData, action: PayloadAction<{ deleteRecId: string | number }>) {
            state.deleteRecId = action.payload.deleteRecId
        },
        deleteListRecordFromStateById(state: UserStateData, action: PayloadAction<{ userList: any[], deleteRecId: string | number }>) {
            let tempList: any[] = action.payload.userList || [];
            let finalPayloadList: any[] = tempList.length ? tempList.filter(item => item?.id != action.payload.deleteRecId) : [...tempList];
            state.userList = finalPayloadList;
            state.totalCount = finalPayloadList.length;  
        },
        setProfileUpdateLoader(state: UserStateData, action: PayloadAction<any>) {
            state.profileUpdateLoader = action.payload.profileUpdateLoader;
        },
    }
});

export const reducer = slice.reducer;

export const getAllUsers = (page: number = 0, limit: number = 5, searchKey: string = ''): AppThunk => async (dispatch, getState) => {
    const { selectedProject } = getState().auth;
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/users?page=${page + 1}&limit=${limit}&searchKey=${searchKey}&project=${selectedProject}`, {}, {});

    if (response) {
        dispatch(slice.actions.getUsersList({ userList: response?.data || [], totalCount: response?.totalCount || 0 }))
    }

    dispatch(slice.actions.loader({ loader: false }));
};

export const saveUser = (payload, history, snackBar:any): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_POST('/api/users', payload, {});

    if (response?.data?.id) {
        snackBar(response?.message, snackbarGlobConfig('success'));
        // toastr.success('Success', response?.message);
        history.replace(`/app/user/create-user/${response?.data.id}`);
        dispatch(slice.actions.getUserSingleData({ selectedUserObject: response?.data }))
    } else {
        toastr.error('Error', response?.message);
    }
};

export const createCandidate = async (payload, history) => {
    
    const response: any = await AXIOS_POST('/api/users/create_candidate', payload, {});
    if (response?.data) {
        toastr.success('Success', response?.message);
        localStorage.setItem('accessToken', response?.data?.token?.token);
        const user = response?.data?.userData
        localStorage.setItem('userDetails', JSON.stringify(user));    
        history.replace(`/app/employeecourse/${response?.data?.userData?.id}`);
    } else {
        toastr.error('Error', response?.message);
    }
    return;
};

export const updateUser = (id, payload, snackBar:any): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_PATCH(`/api/users/${id}`, payload, {});

    if (response?.data?.id) {
        snackBar(response?.message, snackbarGlobConfig('success'));
        // toastr.success('Success', response?.message);
      dispatch(slice.actions.getUserSingleData({ selectedUserObject: response?.data }))
      
    } else {
        toastr.error('Error', response?.message);
    }
};

export const getUserById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/users/' + id, {}, {});

    if (response?.data.id) {
         dispatch(slice.actions.getUserSingleData({ selectedUserObject: response.data }))
        
    }
};

export const deleteUserById = (userList, id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_DELETE('/api/users/' + id, {});
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: null }));

    if (response?.data?.isDeleted) {
        toastr.success('Success', response?.message);
        // dispatch(slice.actions.deleteListRecordFromStateById({userList: userList, deleteRecId: id}));
    } else {
        toastr.error('Error', response?.message);
    }

  return response?.data?.isDeleted == true ? true : false;

};

export const getRoles = (): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/role?page=0&limit=0&searchKey=', {}, {});

    if (response) {
        dispatch(slice.actions.getRoleList({ roleList: response?.data || [] }));
    }
};

export const uploadUserImage = (payload): AppThunk => async (dispatch) => {
    const formData = new FormData();
    formData.append('file', payload);

    const response: any = await AXIOS_POST('/api/users/upload', formData, {});

    if (response?.data) {
        // toastr.success('Success', response?.message);
    } else {
        toastr.error('Error', response?.message);
    }

    return response?.data ? (BASE_URL + response?.data) : '';
};

export const updateUserProfileDetails = (payload: any, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setProfileUpdateLoader({ profileUpdateLoader: true }));
        // const response: any = await AXIOS_PATCH(`/api/auth/update-profile`, payload, {});
        const response: any = await AXIOS_PATCH(`/api/users/me/update`, payload, {});

        if (response?.data?.email) {
            snackBar(response?.message, snackbarGlobConfig('success'));
        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
        }

        dispatch(me());
        return response?.data?.email ? true : false;
    } catch (err) {
        snackBar(err, snackbarGlobConfig ('error' ));
        return false;

    } finally {
        dispatch(slice.actions.setProfileUpdateLoader({ profileUpdateLoader: false }));
    }
};

export const updateUserPassword = (payload: any, snackBar: any): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_PATCH(`/api/users/me/changePassword`, payload, {});

    if (response?.status === true) {
        snackBar(response?.message, snackbarGlobConfig('success'));

    } else {
        snackBar(response?.message, snackbarGlobConfig('error'));
    }
};

export const getCandidateBatchList = (page: number = 1, limit: number = 10, searchKey: string = ''): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/batch/candidate?page=${page}&limit=${limit}&searchKey=${searchKey}`, {}, {});

    let batchList: any[] = [];
    let batchTotalCount: number = 0;

    if (response?.data && response?.data.length) {
        let currentList: any[] = [...response?.data[0]];
        batchTotalCount = response?.data[1] || 0;

        batchList = currentList.map(element => {
            return { label: element?.name || '', value: element?.id || null, createdDate: element?.createdAt || null }
        });
    }
    
    return { batchList: batchList || [], batchTotalCount: batchTotalCount }
};

export const getBatchList = (page: number = 1, limit: number = 10, searchKey: string = ''): AppThunk => async (dispatch, getState) => {
    const { selectedProject } = getState().auth;
    const response: any = await AXIOS_GET(`/api/batch?page=${page}&limit=${limit}&searchKey=${searchKey}&project=${selectedProject}`, {}, {});

    let batchList: any[] = [];
    let batchTotalCount: number = 0;

    if (response?.data && response?.data.length) {
        let currentList: any[] = [...response?.data[0]];
        batchTotalCount = response?.data[1] || 0;

        batchList = currentList.map(element => {
            return { label: element?.name || '', value: element?.id || null, createdDate: element?.createdAt || null }
        });
    }
    
    return { batchList: batchList || [], batchTotalCount: batchTotalCount }
};

export const getProjectList = (): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/projects?page=1&limit=10', {}, {});

    if (response) {
        dispatch(slice.actions.getProjectList({ projectList: response?.data || [] }));
    }
};

/* #region custom functions */
export const setDeleteRecordId = (id) => (dispatch) => {
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: id ? id : null }));
}

export const resetEntireForm = () => (dispatch) => {
    dispatch(slice.actions.getUserSingleData({ selectedUserObject: null }))
}
/* #endregion custom functions */

export default slice;
