import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon,
  TextField,
  MenuItem
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import type { Theme } from 'src/theme';
import Account from './Account';
import Notifications from './Notifications';
import { useDispatch, useSelector } from 'src/store';
import { getProjectList } from 'src/slices/users';
import slice from 'src/slices/auth';

interface TopBarProps {
  className?: string;
  onMobileNavOpen?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#29465B"
  },
  toolbar: {
    minHeight: 64
  },
  projectField: {
    width: '200px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white'
      },
      '&:hover fieldset': {
        borderColor: 'white'
      }
    },
    '& .MuiInputLabel-root': {
      color: 'white'
    },
    '& .MuiInputBase-input': {
      color: 'white'
    }
  }
}));

const TopBar: FC<TopBarProps> = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();
  const { user } = useSelector(state => state.auth)
  const { selectedProject } = useSelector(state => state.auth);
  const isAdmin = user?.role?.name == 'Super Admin' || user?.role?.name == 'Admin';
  const { projectList } = useSelector(state => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    getProject();
    const selectedProject = localStorage.getItem('selectedProject');
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    
    if (selectedProject) {
      dispatch(slice.actions.setSelectedProject(selectedProject));
    } else if (userDetails?.project?.id) {
      dispatch(slice.actions.setSelectedProject(userDetails.project.id));
      localStorage.setItem('selectedProject', userDetails.project.id);
    }
  }, []);
    
  const getProject = () => {
    dispatch(getProjectList());
  }

  const handleProjectChange = (e) => {
    const selectedValue = e.target.value;
    dispatch(slice.actions.setSelectedProject(selectedValue));
    localStorage.setItem('selectedProject', selectedValue);
  };

  return (
    <AppBar
      className={clsx(classes.root, className)}
      {...rest}
    >
      {
        user && (<Toolbar className={classes.toolbar}>
          <Hidden lgUp>
            <IconButton
              color="inherit"
              onClick={onMobileNavOpen}
            >
              <SvgIcon fontSize="small">
                <MenuIcon />
              </SvgIcon>
            </IconButton>
          </Hidden>
          <Hidden mdDown>
            <RouterLink to="/">
            </RouterLink>
            <Logo />

          </Hidden>
          <Box
            ml={2}
            flexGrow={1}
          />
          {isAdmin ? (
               <TextField
                 select
                 fullWidth
                 label="Project"
                 name="project"
                 value={selectedProject}
                 onChange={handleProjectChange}
                 variant="outlined"
                 size="small"
                 className={classes.projectField}
               >
                 {projectList?.map((option) => (
                   <MenuItem key={option.id} value={option.id}>
                     {option.projectName}
                   </MenuItem>
                 ))}
               </TextField>
             ) : (
              <Box style={{color: 'white'}}>
              {user.project.projectName}
            </Box>
              )}
          {/* <Notifications /> */}
          <Box ml={2}>
            <Account />
          </Box>
        </Toolbar>)
      }

    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => { }
};

export default TopBar;
