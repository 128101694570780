import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { AXIOS_DELETE, AXIOS_GET, AXIOS_PATCH, AXIOS_POST, BASE_URL } from './service/apiService';
import {toastr} from 'react-redux-toastr';
import { me } from './auth';
import { snackbarGlobConfig } from 'src/globalConfig';
import { notesStateData } from 'src/types/notes';

const initialState: notesStateData = {
    notesList: [],
    notesTotalCount: 0,
    selectedNotesObject: null,
    loader: false,
    deleteRecId: null,
};

const slice = createSlice({
    name: 'notes',
    initialState,
    reducers: {
        getNotesList(state: notesStateData, action: PayloadAction<{ notesList: any[], notesTotalCount: number }>) {
            state.notesList = action.payload.notesList;
            state.notesTotalCount = action.payload.notesTotalCount;
        },
        getSingleNote(state: notesStateData, action: PayloadAction<{ selectedNotesObject: any }>) {
            state.selectedNotesObject = action.payload.selectedNotesObject
        },
        loader(state: notesStateData, action: PayloadAction<any>) {
            state.loader = action.payload.loader
        },
        setDeleteRecordId(state: notesStateData, action: PayloadAction<{ deleteRecId: string | number }>) {
            state.deleteRecId = action.payload.deleteRecId
        },
        deleteListRecordFromStateById(state: notesStateData, action: PayloadAction<{ notesList: any[], deleteRecId: string | number }>) {
            let tempList: any[] = action.payload.notesList || [];
            let finalPayloadList: any[] = tempList.length ? tempList.filter(item => item?.id != action.payload.deleteRecId) : [...tempList];
            state.notesList = finalPayloadList;
            state.notesTotalCount = finalPayloadList.length;  
        },
    }
});

export const reducer = slice.reducer;

export const getAllNotes = (page: number = 0, limit: number = 5, searchKey: string = ''): AppThunk => async (dispatch, getState) => {
    const { selectedProject } = getState().auth;
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/notes?page=${page + 1}&limit=${limit}&searchKey=${searchKey}&project=${selectedProject}`, {}, {});

    if (response) {
        dispatch(slice.actions.getNotesList({ notesList: response?.data?.data || [], notesTotalCount: response?.data?.totalCount || 0 }))
    }

    dispatch(slice.actions.loader({ loader: false }));
};

export const addNote = (payload, history): AppThunk => async (dispatch, getState) => {
    const { selectedProject } = getState().auth;
    const response: any = await AXIOS_POST(`/api/notes?project=${selectedProject}`, payload, {});

    if (response?.status) {
        toastr.success('Success', response?.message);
        history.replace(`/app/notes`);
        // dispatch(slice.actions.getSingleNote({ selectedNotesObject: response?.data }))
    } else {
        toastr.error('Error', response?.message);
    }
  return response?.status == true ? true : false;
};

export const getNoteById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/notes/' + id, {}, {});

    if (response?.data.id) {
        dispatch(slice.actions.getSingleNote({ selectedNotesObject: response.data }))
        
    }
};;

export const updateNote = (id, payload, history): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_PATCH(`/api/notes/${id}`, payload, {});

    if (response?.status) {
        toastr.success('Success', response?.message);
        history.replace(`/app/notes`);
      dispatch(slice.actions.getSingleNote({ selectedNotesObject: response?.data }))
    } else {
        toastr.error('Error', response?.message);
    }
  return response?.status == true ? true : false;
};

export const deleteNoteById = (noteList, id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_DELETE('/api/notes/' + id, {});
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: null }));

    if (response?.status) {
        toastr.success('Success', response?.message);
        // dispatch(slice.actions.deleteListRecordFromStateById({userList: userList, deleteRecId: id}));
    } else {
        toastr.error('Error', response?.message);
    }

  return response?.status == true ? true : false;

};


/* #region custom functions */
export const setDeleteRecordId = (id) => (dispatch) => {
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: id ? id : null }));
}

export const resetEntireForm = () => (dispatch) => {
    dispatch(slice.actions.getSingleNote({ selectedNotesObject: null }))
}
/* #endregion custom functions */

export default slice;
